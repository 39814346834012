import { ACCION_EJEC } from '@/utils/consts'
import { likeRegExp } from '@/offline/database'

export default {
  async selectDescargados (Vue, filter, search, sorter, page, idtecnico) {
    const tables = Vue.$offline.db.tables
    const inPartesTecnicoActual = await Vue.$offline.parteTrabajoTecnico.inPartesDescargados(idtecnico)
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.parte_trabajo.idparte_trabajo.eq(search),
          tables.orden_trabajo.norden_trabajo.eq(search),
          tables.cliente.nombre.match(reQ),
          tables.sistema.descripcion.match(reQ),
        )
      )
    }
    if (filter.idparteTrabajo.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(filter.idparteTrabajo.value),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.parte_trabajo.idparte_trabajo.in(inPartesTecnicoActual),
        ...whereSearch,
        ...whereFilter
      )
    )
    const rows = await this.selectDescargadosBase(Vue)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
      .where(...where)
      .orderBy(tables.parte_trabajo.idparte_trabajo)
      .exec()
    return [
      await this._addSubqueriesDescargados(Vue, rows),
      []
    ]
  },
  async selectDescargadosRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    const rows = await this.selectDescargadosBase(Vue)
      .where(tables.parte_trabajo.idparte_trabajo.in(pks))
      .exec()
    return this._addSubqueriesDescargados(Vue, rows)
  },
  selectParteConAnomalias (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(tables.parte_trabajo.idparte_trabajo)
      .from(tables.tanomalia_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
      )
      .innerJoin(
        tables.parte_trabajo,
        tables.checklist_ot.idorden_trabajo.eq(tables.parte_trabajo.idorden_trabajo)
      )
      .where(
        tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo)
      )
      .exec()
  },
  selectParteAcciones (Vue, idparteTrabajo, estados) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        tables.parte_trabajo.idparte_trabajo,
        Vue.$offline.db.fn.count(
          tables.accion_ejec.idaccion_ejec
        ).as('cant_acciones')
      )
      .from(tables.accion_ejec)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.accion_ejec.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.parte_trabajo,
        tables.orden_trabajo_matsist.idorden_trabajo.eq(tables.parte_trabajo.idorden_trabajo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.accion_ejec.idest_accion_ejec.in(estados),
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo)
        )
      )
      .groupBy(
        tables.parte_trabajo.idparte_trabajo
      )
      .exec()
  },
  async _addSubqueriesDescargados (Vue, rows) {
    for (let row of rows) {
      row.direccion = await Vue.$offline.clienteDireccion.formatearDireccion(row.cliente_direccion)
      // motivo de bloqueo
      const motivoBloqueoOt = await Vue.$offline.tmotivoBloqueoOt.row(row.orden_trabajo.idtmotivo_bloqueo_ot)
      if (motivoBloqueoOt !== null) {
        row.idtmotivo_bloqueo_ot = motivoBloqueoOt.tmotivo_bloqueo_ot.idtmotivo_bloqueo_ot
        row.tmotivo_bloqueo_ot_descripcion = motivoBloqueoOt.tmotivo_bloqueo_ot.descripcion
      }
      // checklist con anomalías
      const conAnomalias = await this.selectParteConAnomalias(Vue, row.parte_trabajo.idparte_trabajo)
      row.checklist_con_anomalias = conAnomalias.length > 0
      // totales de acciones
      const accionesTotal = await this.selectParteAcciones(
        Vue, row.parte_trabajo.idparte_trabajo,
        [ACCION_EJEC.estados.realizada, ACCION_EJEC.estados.pendiente]
      )
      const accionesRealizadas = await this.selectParteAcciones(
        Vue, row.parte_trabajo.idparte_trabajo,
        [ACCION_EJEC.estados.realizada]
      )
      if (accionesTotal.length > 0) {
        row.cant_acciones = accionesTotal[0].cant_acciones
      } else {
        row.cant_acciones = 0
      }
      if (accionesRealizadas.length > 0) {
        row.cant_acciones_realizadas = accionesRealizadas[0].cant_acciones
      } else {
        row.cant_acciones_realizadas = 0
      }
      row.cant_acciones_pendientes = row.cant_acciones - row.cant_acciones_realizadas
    }
    return rows
  },
  selectDescargadosBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .leftOuterJoin(
        tables.cliente_direccion,
        tables.sistema.idcliente_direccion_sist.eq(tables.cliente_direccion.idcliente_direccion)
      )
      .innerJoin(
        tables.tsistema,
        tables.sistema.idtsistema.eq(tables.tsistema.idtsistema)
      )
      .innerJoin(
        tables.tactuacion_tsistema,
        tables.orden_trabajo.idtactuacion_tsistema.eq(tables.tactuacion_tsistema.idtactuacion_tsistema)
      )
      .innerJoin(
        tables.tactuacion,
        tables.tactuacion_tsistema.idtactuacion.eq(tables.tactuacion.idtactuacion)
      )
      .innerJoin(
        tables.orden_trabajo_prioridad,
        tables.orden_trabajo.idorden_trabajo_prioridad.eq(tables.orden_trabajo_prioridad.idorden_trabajo_prioridad)
      )
      .leftOuterJoin(
        tables.almacen,
        tables.parte_trabajo.idalmacen.eq(tables.almacen.idalmacen)
      )
      .innerJoin(
        tables.forma_pago,
        tables.sistema.idforma_pago.eq(tables.forma_pago.idforma_pago)
      )
      .leftOuterJoin(
        tables.tincidencia_ot,
        tables.orden_trabajo.idtincidencia_ot.eq(tables.tincidencia_ot.idtincidencia_ot)
      )
      .leftOuterJoin(
        tables.tcierre_parte,
        tables.parte_trabajo.idtcierre_parte.eq(tables.tcierre_parte.idtcierre_parte)
      )
      .leftOuterJoin(
        tables.zona,
        tables.cliente_direccion.idzona_tecnico.eq(tables.zona.idzona)
      )
      .leftOuterJoin(
        tables.checklist_ot,
        tables.orden_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
  }
}
